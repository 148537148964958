import UserObject from "./UserObject"

export default class TagObject {

  id = null;
  name = null;
  container = null;
  users = [];
  isMain = false;
  azure = false;
  yandex = false;
  archived = false;
  files = 0;

  constructor(tag = null) {
    if (tag) {
      this.id = tag.id;
      this.name = tag.name;
      this.users = tag.users.map(u => new UserObject(u));
      this.container = tag.container;
      this.isMain = tag.isMain;
      this.azure = tag.azureBlobs;
      this.yandex = tag.s3Objects;
      this.archived = tag.archived;
      this.files = tag.files;
    }
  }

  toRequest() {
    return {
      Id: this.id,
      Name: this.name,
      IsMain: this.isMain,
      AzureBlobs: this.azure,
      S3Objects: this.yandex,
      Archived: this.archived,
      Users: this.users.map(u => u.email)
    }
  }

  toDummyResponse() {
    return {
      id: this.id,
      name: this.name,
      isMain: this.isMain,
      azureBlobs: this.azure,
      s3Objects: this.yandex,
      archived: this.archived,
      files: this.files,
      users: this.users
    }
  }
}