<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Файлы</h2>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">

        <v-row class="mt-10">
          <v-col class="container-selector">
            <h4 class="mt-2 mr-5">Контейнер</h4>
            <v-select :items="containers"
                      v-model="container"
                      label="Выберите контейнер"
                      solo
                      dense
                      no-data-text="Список контейнеров пуст"
                      @change="onContainerSelected">
            </v-select>
          </v-col>
        </v-row>

        <v-row v-show="container">
          <search-panel placeholder="Поиск по названию и ID"
                          @search="onSearch" />
        </v-row>

        <state-container ref="list">
          <v-container class="full-container">
            <v-row>
              <v-col>
                <custom-table :items="list"
                       :headers="headers"
                       :loading="pageLoading"
                       @pagination="onPagination">

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt | date }}
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if="isAdmin"
                            @click="onEditClicked(item)">
                      mdi-pencil
                    </v-icon>
                  </template>

                </custom-table>
              </v-col>
            </v-row>
          </v-container>
        </state-container>
      </v-container>
    </state-container>

    <overlay :value="loading" />
    <custom-snackbar ref="snackbar" />

    <custom-dialog v-model="inDialog">
      <v-row class="mb-10" v-if="loadingEdit">
        <overlay :value="loadingEdit" :opacity="0"/>
      </v-row>
      <div v-else>
        <v-row>
          <v-col style="text-align: start">
            <h3>Файл</h3>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-text-field v-model="file.id"
                          label="ID"
                          outlined
                          dense
                          readonly
                          hide-details />
          </v-col>
        </v-row>
    
        <v-row class="mt-4">
          <v-col>
            <v-form @submit.prevent ref="form" lazy-validation>
              <v-text-field v-model="file.systemName"
                            label="Название"
                            placeholder="Только символы латинского алфавита и цифры"
                            outlined
                            dense
                            required
                            :rules="validationRules.systemNameRules"
                            class="mb-n5" />
            </v-form>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="file.mainTag"
                            :items="tagsMetaList"
                            placeholder="Введите название тега для поиска"
                            label="Основной тег"
                            outlined
                            dense
                            class="mb-n6">
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-autocomplete v-model="file.tags"
                            :items="subTagsMetaList"
                            placeholder="Введите название тега для поиска"
                            label="Информационные теги"
                            outlined
                            dense
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            return-object
                            no-data-text="В контейнере нет информационных тегов"
                            class="mb-n6">
            </v-autocomplete>
          </v-col>
        </v-row>
    
        <v-row class="mt-5">
          <v-col style="text-align: start">
            <h3>Версии</h3>
          </v-col>
        </v-row>
    
        <v-row>
          <v-col>
            <custom-table :items="file.versions"
                          :items-per-page="10"
                          :headers="versionsHeaders">

              <!-- eslint-disable-next-line -->
              <template v-slot:item.size="{ item }">
                {{ item.size | bytes }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | dateTime }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.link="{ item }">
                <a v-if="item.link" :href="item.link" target="_blank"> просмотреть </a>
              </template>
    
            </custom-table>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col style="text-align: start">
            <h3>Права доступа</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="primary"
                  :loading="isMetaLoading"
                  :disabled="isMetaLoading || isFileDeleting || isFileSaving"
                  @click="onAccessAddClicked">
              Предоставить доступ
            </v-btn>
          </v-col>
        </v-row>
    
        <v-row>
          <v-col>
            <custom-table :items="file.accesses"
                          :items-per-page="10"
                          :headers="accessesHeaders">
            
              <!-- eslint-disable-next-line -->
              <template v-slot:item.name="{ item }">
                {{ isAnonymousObject(item) ? "анонимно" : item.name }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.email="{ item }">
                {{ isAnonymousObject(item) ? "-" : item.email }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.info="{ item }">
                <div v-if="isAnonymousObject(item)">
                  <div>
                    {{ item.allowDownload ? "чтение/скачивание" : "чтение" }}
                  </div>
                  <div>
                    {{ item.pinCode ? "доступ по пин-коду" : "доступ по ссылке" }} до {{ item.expireOn | date }}
                  </div>
                </div>
                <div v-else-if="isTagObject(item)">
                  <div>
                    чтение/запись
                  </div>
                  <div>
                    унаследовано от тега {{ item.tag }}
                  </div>
                </div>
                <div v-else>
                  <div>
                    чтение/запись
                  </div>
                  <div>
                    доступ к файлу по {{ item.expireOn | date }}
                  </div>
                </div>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="!isTagObject(item)" @click="onAccessEditClicked(item)">
                  mdi-pencil
                </v-icon>
                <v-icon v-if="!isTagObject(item)" @click="onAccessDeleteClicked(item)">
                  mdi-delete
                </v-icon>
              </template>
    
            </custom-table>
          </v-col>
        </v-row>
    
        <v-row class="mt-5 mb-1">
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="error"
                  :disabled="isMetaLoading || isFileDeleting || isFileSaving"
                  :loading="isFileDeleting"
                  @click="onDeleteFileClicked">
              Удалить файл
            </v-btn>
          </v-col>
          <v-col style="display: flex; justify-content: start;">
            <v-btn color="primary"
                  :disabled="isMetaLoading || isFileDeleting || isFileSaving"
                  :loading="isFileSaving"
                  @click="onSaveFileClicked">
              Сохранить изменения
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      
    </custom-dialog>

    <custom-dialog v-model="inAccessDialog">
      <v-row>
        <v-col style="text-align: start">
          <h3>Правило доступа к файлу</h3>
        </v-col>
      </v-row>

      <v-form @submit.prevent ref="delegationForm" lazy-validation>
      <v-row class="mt-4">
        <v-col>
          <v-select v-model="accessType"
                    :items="accessTypes"
                    item-text="title"
                    item-value="value"
                    label="Тип доступа"
                    outlined
                    dense
                    :readonly="isAccessEdit"
                    @change="onAccessTypeChanged"
                    required
                    :rules="validationRules.delegationRules.typeRules"
                    class="mb-n6" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="date-selector">
          <span class="mr-5">Интервал предоставления доступа</span>
          <custom-date-picker v-model="accessDates" />
        </v-col>
      </v-row>
  
      <v-row v-if="access && accessType != 'User'" class="mt-4">
        <v-col>
          <v-checkbox v-model="access.allowDownload"
                      label="Разрешить скачивание"
                      class="mt-n2">
          </v-checkbox>
        </v-col>
      </v-row>
  
      <v-row v-if="access && accessType == 'Pin'" class="mt-n4">
        <v-col>
          <v-text-field v-model="access.pinCode"
                        dense
                        outlined
                        required
                        :rules="pinRules"
                        label="Пин-код" />
        </v-col>
      </v-row>
  
      <v-row v-if="access && accessType == 'User'" class="mt-3">
        <v-col>
          <v-autocomplete v-model="access.email"
                          :items="users"
                          item-value="email"
                          item-text="email"
                          placeholder="Введите email для поиска"
                          label="Email"
                          outlined
                          dense
                          required
                          :rules="userRules"
                          @change="onUserChanged" />
        </v-col>
      </v-row>

      </v-form>
    
      <v-row class="mt-5 mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 @click="inAccessDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 @click="onAccessSaveClicked">
            {{ isAccessEdit ? "Сохранить изменения" : "Добавить" }}
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>
import "@/assets/styles/pages.css"

import ApiService from "@/services/api"
import DialogService from "@/services/dialog"

import Filters from "@/helpers/ui/filters"
import Rules from "@/helpers/validators/files"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"
import Snackbar from "@/components/SnackBar.vue"
import Dialog from "@/components/Dialog.vue"
import DatePicker from "@/components/DatePicker.vue"
import SearchPanel from "@/components/SearchPanel.vue"

import StateMixins from "@/mixins/state"
import ErrorsMixins from "@/mixins/errors"

import FileObject from "@/store/objects/files/FileObject"
import FileAccessObject from "@/store/objects/files/FileAccessObject"
import AnonymousAccessObject from "@/store/objects/files/AnonymousAccessObject"
import TagAccessObject from "@/store/objects/files/TagAccessObject"

import BlConsts from "@/store/consts/bl"

export default {
  mixins: [StateMixins, ErrorsMixins],

  computed: {
    isAdmin() {
      return this.$store.getters.role == "Admin";
    },

    pinRules() {
      if (this.accessType == "Pin")
        return this.validationRules.delegationRules.pinRules;
      
      return [];
    },
    userRules() {
      if (this.accessType == "User")
        return this.validationRules.delegationRules.userRules;

      return [];
    }
  },

  data() {
    return {
      loading: true,
      loadingEdit: true,
      searchQuery: null,
      headers: [
        { text: "Название", value: "systemName" },
        { text: "Основной тег", value: "mainTag" },
        { text: "Создан", value: "createdAt", filterable: false },
        { text: "Версия", value: "version", filterable: false },
        { text: "Информационных тегов", value: "tags" },
        { text: "", value: "actions", sortable: false, align: "end" },
        { value: "id", align: " d-none" }
      ],
      list: [],

      inDialog: false,
      inAccessDialog: false,
      isAccessEdit: false,

      containers: [],
      container: null,
      file: new FileObject(),
      access: null,
      accessType: null,
      accessDates: {},
      tagsMetaList: null,
      subTagsMetaList: null,
      users: null,

      isMetaLoading: false,
      isFileDeleting: false,
      isFileSaving: false,

      accessEditIndex: 0,
      editIndex: 0,

      versionsHeaders: [
        { text: "Название", value: "name" },
        { text: "Версия", value: "version" },
        { text: "Размер", value: "size" },
        { text: "Загружен", value: "createdAt" },
        { text: "Ссылка", value: "link" },
      ],
      accessesHeaders: [
        { text: "ФИО", value: "name" },
        { text: "Email", value: "email" },
        { text: "Информация", value: "info" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],
      accessTypes: BlConsts.accessTypes,

      validationRules: Rules,

      continuationToken: null,
      pageLoading: false
    }
  },

  mounted() {
    this.setEmptyState("list");
    this.loadContainersMeta();
  },

  methods: {
    getDatesOffset() {
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + this.$store.getters.days - 1);
      return { start: startDate, end: endDate };
    },

    loadContainersMeta() {
      ApiService.containers.getNames()
        .then(response => {
          this.containers = response.data;
          if (!response.data.length)
            this.setNoDataState("main");
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    },

    loadUsersNames(edit, item) {
      if (!this.users) {
        this.isMetaLoading = true;
        let roles = ["User"];
        let notAdded = false;
        ApiService.users.getNamesForContainer(this.container, notAdded, roles)
          .then(response => {
            this.users = response.data;
            if (edit)
              this.openAccessEditModal(item);
            else
              this.openAccessAddModal();
          })
          .catch(error => {
            this.isMetaLoading = false;
            this.showError(error.response.data.error);
          });
      }
      else {
        if (edit)
          this.openAccessEditModal(item);
        else
          this.openAccessAddModal();
      }
    },

    onSearch(search) {
      this.searchQuery = search;

      this.list = [];
      
      this.loading = true;
      this.loadContainerFiles(this.continuationToken, this.searchQuery);
    },

    onContainerSelected(container) {
      this.container = container;
      this.subTagsMetaList = null;
      this.tagsMetaList = null;
      this.users = null;
      this.list = [];
      
      this.loading = true;
      this.loadContainerFiles(null, null);
    },

    loadContainerFiles(token, search) {
      ApiService.files.getContainerFiles(this.container, token, search)
        .then(response => {
          this.list.push(...response.data.data);
          this.continuationToken = response.data.meta.continuationToken;
          if (!response.data.data.length)
            this.setNoDataState("list");
          else
            this.setBaseState("list");
          this.loading = this.pageLoading = false;
        })
        .catch(error => {
          this.setErrorState("list", error);
          this.loading = this.pageLoading = false;
          console.log(error);
        });
    },

    onPagination(params) {
      if (this.continuationToken == null || params.pageCount - params.page != 1)
        return;
      
      this.pageLoading = true;
      this.loadContainerFiles(this.continuationToken, this.searchQuery);
    },

    onEditClicked(item) {
      this.loadTagsMeta(item);
      this.loadSubTagsMeta(item);
    },

    loadTagsMeta(item) {
      if (!this.tagsMetaList) {
        this.inDialog = true;
        ApiService.tags.getContainerMainTagsNames(this.container)
          .then(response => {
            this.tagsMetaList = response.data;
            this.openEditModal(item);
          })
      }
      else {
        this.openEditModal(item);
      }
    },

    loadSubTagsMeta(item) {
      if (!this.subTagsMetaList) {
        this.inDialog = true;
        ApiService.tags.getContainerInfoTagsNames(this.container)
          .then(response => {
            this.subTagsMetaList = response.data;
            this.openEditModal(item);
          });
      }
      else {
        this.openEditModal(item);
      }
    },

    openEditModal(item) {
      if (!this.subTagsMetaList || !this.tagsMetaList)
        return;

      this.inDialog = true;
      this.editIndex = this.list.indexOf(item);
      this.loadingEdit = true;
      ApiService.files.getContainerFile(this.container, item.id)
        .then(response => {
          this.file = new FileObject(response.data);

          this.loadingEdit = false;
        })
        .catch(error => {
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);
            
          this.loadingEdit = false;
        });
    },

    onAccessDeleteClicked(item) {
      let index = this.file.accesses.indexOf(item);
      this.$delete(this.file.accesses, index);
    },

    onAccessEditClicked(item) {
      this.loadUsersNames(true, item);
    },

    onAccessAddClicked() {
      this.loadUsersNames(false);
    },

    openAccessAddModal() {
      this.isMetaLoading = false;
      this.inAccessDialog = true;
      this.isAccessEdit = false;
      this.access = null;
      this.accessType = null;
      this.accessDates = this.getDatesOffset();

      if (this.$refs.delegationForm)
        this.$refs.delegationForm.resetValidation();
    },

    openAccessEditModal(item) {
      this.isMetaLoading = false;
      this.accessEditIndex = this.file.accesses.indexOf(item);
      if (item instanceof FileAccessObject) {
        this.access = new FileAccessObject(item);
        this.accessType = "User";
      }
      else {
        this.access = new AnonymousAccessObject(item);
        if (item.pinCode)
          this.accessType = "Pin";
        else
          this.accessType = "Anon";
      }
      this.inAccessDialog = true;
      this.isAccessEdit = true;
      this.accessDates = { start: this.access.startsOn, end: this.access.expireOn };
    },

    onAccessSaveClicked() {
      if (!this.$refs.delegationForm.validate())
        return;

      this.access.startsOn = this.accessDates.start;
      this.access.expireOn = this.accessDates.end;

      if (this.isAccessEdit)
        this.$set(this.file.accesses, this.accessEditIndex, this.access);
      else
        this.file.accesses.unshift(this.access);
        
      this.inAccessDialog = false;
      this.accessType = null;
    },

    onUserChanged(email) {
      let user = this.users.find(u => u.email == email);
      this.access.name = user.name;
    },

    onDeleteFileClicked() {
      DialogService.showConfirm("Действительно удалить файл?")
        .then(() => {
          this.isFileDeleting = true;
          ApiService.files.delete(this.container, this.file.id)
            .then(() => {
              this.isFileDeleting = false;

              this.$delete(this.list, this.editIndex);

              this.inDialog = false;
            })
            .catch(error => {
              this.isFileDeleting = false;
              this.showError(error.response.data.error);
            });
        });
    },

    onSaveFileClicked() {
      if (!this.$refs.form.validate())
        return;

      this.isFileSaving = true;
      let body = this.file.toRequest();
      ApiService.files.update(this.container, body)
        .then(() => {
          this.isFileSaving = false;

          let dummyResponse = this.file.toDummyResponse();
          this.$set(this.list, this.editIndex, dummyResponse);

          this.inDialog = false;
        })
        .catch(error => {
          this.isFileSaving = false;
          this.showError(error.response.data.error);
        });
    },

    onAccessTypeChanged(type) {
      if (type == "Anon" || type == "Pin")
        this.access = new AnonymousAccessObject();
      else
        this.access = new FileAccessObject();
    },

    isAnonymousObject(item) {
      return item instanceof AnonymousAccessObject;
    },

    isTagObject(item) {
      return item instanceof TagAccessObject;
    },

    onDialogClosed() {
      if (!this.inDialog)
        this.$refs.delegationForm.resetValidation();
    }
  },

  filters: {
    dateTime: Filters.dateTime,
    date: Filters.date,
    bytes: Filters.bytes
  },

  watch: {
    inAccessDialog: 'onDialogClosed'
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomSnackbar: Snackbar,
    CustomDialog: Dialog,
    CustomDatePicker: DatePicker,
    SearchPanel
  }
};
</script>
