<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Теги</h2>
      </v-col>
      <v-col style="text-align: end">
        <v-btn v-show="container && isAdmin"
               color="primary"
               @click="openCreateModal">
          Создать
        </v-btn>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">

        <v-row class="mt-10">
          <v-col class="container-selector">
            <h4 class="mt-2 mr-5">Контейнер</h4>
            <v-select :items="containers"
                      v-model="container"
                      label="Выберите контейнер"
                      solo
                      dense
                      @change="onContainerSelected">
            </v-select>
          </v-col>
        </v-row>

        <v-row v-show="container">
          <search-panel placeholder="Поиск по названию и ID"
                          @search="onSearch" />
        </v-row>

        <state-container ref="list">
          <v-container class="full-container">
            <v-row>
              <v-col>
                <custom-table :items="list"
                       :headers="headers"
                       :loading="pageLoading"
                       @pagination="onPagination">

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.isMain="{ item }">
                    {{ item.isMain ? "Да" : "Нет" }}
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.storages="{ item }">
                    {{ item | storages }}
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if="isAdmin"
                            @click="onEditClicked(item)">
                      mdi-pencil
                    </v-icon>
                  </template>

                </custom-table>
              </v-col>
            </v-row>
          </v-container>
        </state-container>
      </v-container>
    </state-container>

    <overlay :value="loading" />
    <custom-snackbar ref="snackbar" />

    <custom-dialog v-model="inDialog">
      <v-row class="mb-10" v-if="loadingEdit">
        <overlay :value="loadingEdit" :opacity="0"/>
      </v-row>
      <div v-else>
        <v-row>
          <v-col style="text-align: start">
            <h3>Тег</h3>
          </v-col>
        </v-row>

        <v-row class="mt-4"
              v-show="isEdit">
          <v-col>
            <v-text-field v-model="tag.id"
                          label="ID"
                          outlined
                          dense
                          readonly
                          hide-details />
          </v-col>
        </v-row>
    
        <v-row class="mt-4 mb-4">
          <v-col>
            <v-select :items="containers"
                      v-model="tag.container"
                      label="Контейнер"
                      outlined
                      dense
                      readonly
                      hide-details>
            </v-select>
          </v-col>
        </v-row>

        <v-form @submit.prevent ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field v-model="tag.name"
                            placeholder="Только символы латинского алфавита"
                            label="Название"
                            outlined
                            dense
                            required
                            :rules="validationRules.tagNameRules"
                            class="mb-n6" />
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col>
            <v-checkbox v-model="tag.isMain"
                        :disabled="isEdit"
                        label="Этот тег является основным (поддерживает назначение прав доступа и управление размещением в хранилищах)">
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col style="text-align: start">
            <h3>Хранилища</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="tag.azure"
                        :disabled="!tag.isMain"
                        label="Microsoft Azure"
                        class="mt-1">
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="tag.yandex"
                        :disabled="!tag.isMain"
                        label="Yandex Cloud"
                        class="mt-1">
            </v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="tag.archived"
                        :disabled="!tag.isMain"
                        label="Размещать файлы с этим тегом в архивных хранилищах"
                        class="mt-n5">
            </v-checkbox>
          </v-col>
        </v-row>
    
        <v-row class="mt-5"
              v-show="!isEdit || (isEdit && tag.isMain)">
          <v-col style="text-align: start">
            <h3>Пользователи</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="primary"
                  :loading="isUsersLoading"
                  :disabled="isUsersLoading || (!isEdit && !tag.isMain)"
                  @click="onAddUserClicked">
              Добавить пользователя
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-show="!isEdit || (isEdit && tag.isMain)">
          <v-col>
            <custom-table :items="tag.users"
                          :items-per-page="10"
                          :headers="editHeaders">

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon @click="onDeleteUserClicked(item)">
                  mdi-delete
                </v-icon>
              </template>

            </custom-table>
          </v-col>
        </v-row>
    
        <v-row class="mt-5 mb-1">
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="error"
                  :disabled="!isEdit || isTagDeleting || isTagSaving"
                  :loading="isTagDeleting"
                  @click="onDeleteTagClicked">
              Удалить тег
            </v-btn>
          </v-col>
          <v-col style="display: flex; justify-content: start;">
            <v-btn color="primary"
                  :loading="isTagSaving"
                  :disabled="isTagSaving || isTagDeleting"
                  @click="onSaveTagClicked">
              {{ isEdit ? "Сохранить изменения" : "Создать" }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      
    </custom-dialog>

    <custom-dialog v-model="inUsersDialog">
      <v-row>
        <v-col>
          <custom-table :items="usersForSelect"
                        :items-per-page="-1"
                        :headers="editHeaders"
                        :fixedHeader="true"
                        @click="onUserSelected">  
          </custom-table>
        </v-col>
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>
import "@/assets/styles/pages.css"

import ApiService from "@/services/api"
import DialogService from "@/services/dialog"

import Filters from "@/helpers/ui/filters"
import Rules from "@/helpers/validators/tags"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"
import Snackbar from "@/components/SnackBar.vue"
import Dialog from "@/components/Dialog.vue"
import SearchPanel from "@/components/SearchPanel.vue"

import StateMixins from "@/mixins/state"
import ErrorsMixins from "@/mixins/errors"

import TagObject from "@/store/objects/tags/TagObject"

export default {
  mixins: [StateMixins, ErrorsMixins],

  computed: {
    isAdmin() {
      return this.$store.getters.role == "Admin";
    },

    usersForSelect() {
      if (!this.tag || !this.users) {
        return [];
      }
      else {
        return this.users.filter(u => this.tag.users.find(tu => tu.email == u.email) == undefined);
      }
    }
  },

  data() {
    return {
      loading: true,
      loadingEdit: false,
      searchQuery: null,
      headers: [
        { text: "Название", value: "name" },
        { text: "Основной", value: "isMain", filterable: false },
        { text: "Хранилища", value: "storages", filterable: false },
        { text: "Файлов", value: "files", filterable: false },
        { text: "", value: "actions", sortable: false, align: "end" },
        { value: "id", align: " d-none" }
      ],
      containers: [],
      container: null, 
      users: null,
      list: [],

      inDialog: false,
      isEdit: false,
      editHeaders: [
        { text: "ФИО", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],
      tag: new TagObject(),
      isUsersLoading: false,
      inUsersDialog: false,
      isTagSaving: false,
      isTagDeleting : false,

      validationRules: Rules,

      continuationToken: null,
      pageLoading: false
    }
  },

  mounted() {
    this.setEmptyState("list");
    this.loadMeta();
  },

  methods: {
    loadMeta() {
      ApiService.containers.getNames()
        .then(response => {
          this.containers = response.data;
          if (!response.data.length)
            this.setNoDataState("main");
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        })
    },

    onSearch(search) {
      this.searchQuery = search;

      this.list = [];

      this.loading = true;
      this.loadContainerTags(this.continuationToken, this.searchQuery);
    },

    onContainerSelected() {
      this.users = null;
      this.list = [];

      this.loading = true;
      this.loadContainerTags(null, null);
    },

    loadContainerTags(token, search) {
      ApiService.tags.getContainerTags(this.container, token, search)
        .then(response => {
          this.list.push(...response.data.data);
          this.continuationToken = response.data.meta.continuationToken;
          if (!response.data.data.length)
            this.setNoDataState("list");
          else
            this.setBaseState("list");
          this.loading = this.pageLoading = false;
        })
        .catch(error => {
          this.setErrorState("list", error);
          this.loading = this.pageLoading = false;
          console.log(error);
        });
    },

    onPagination(params) {
      if (this.continuationToken == null || params.pageCount - params.page != 1)
        return;
      
      this.pageLoading = true;
      this.loadContainerFiles(this.continuationToken, this.searchQuery);
    },

    openCreateModal() {
      this.tag = new TagObject();
      this.tag.container = this.container;
      this.inDialog = true;
      this.isEdit = false;

      if (this.$refs.form)
        this.$refs.form.resetValidation();
    },

    onEditClicked(item) {
      this.inDialog = true;
      this.loadingEdit = true;
      ApiService.tags.getContainerTag(this.container, item.id)
        .then(response => {
          this.tag = new TagObject(response.data);
          this.tag.container = this.container;
          this.isEdit = true;
          this.loadingEdit = false;
        })
        .catch(error => {
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);
            
          this.loadingEdit = false;
        });
    },

    onAddUserClicked() {
      if (!this.users) {
        this.isUsersLoading = true;
        ApiService.users.getNamesForContainer(this.container)
          .then(response => {
            this.users = response.data;
            this.isUsersLoading = false;
            this.inUsersDialog = true;
          })
          .catch(error => {
            this.isUsersLoading = false;
            this.showError(error.response.data.error);
          })
      }
      else {
        this.inUsersDialog = true;
      }
    },

    onUserSelected(user) {
      this.inUsersDialog = false;
      this.tag.users.unshift(user);
    },

    onDeleteUserClicked(user) {
      var foundIndex = this.tag.users.findIndex(c => c.email == user.email);
      this.$delete(this.tag.users, foundIndex);
    },

    onSaveTagClicked() {
      if (!this.$refs.form.validate())
        return;

      this.isTagSaving = true;
      let body = this.tag.toRequest();
      if (this.isEdit) {
        ApiService.tags.update(this.tag.container, body)
          .then(() => {
            this.inDialog = false;
            this.isTagSaving = false;

            let dummyResponse = this.tag.toDummyResponse();
            let foundIndex = this.list.findIndex(t => t.id == dummyResponse.id);
            this.$set(this.list, foundIndex, dummyResponse);
          })
          .catch(error => {
            this.isTagSaving = false;
            this.showError(error.response.data.error);
          });
      }
      else {
        ApiService.tags.create(this.tag.container, body)
          .then(response => {
            this.inDialog = false;
            this.isTagSaving = false;

            this.list.unshift(response.data);
            this.setBaseState("list");
          })
          .catch(error => {
            this.isTagSaving = false;
            this.showError(error.response.data.error);
          });
      }
    },

    onDeleteTagClicked() {
      DialogService.showConfirm(this.tag.isMain ? "Удаление главного тега приведет к удалению всех файлов. Действительно удалить тег?" :  "Действительно удалить тег?")
        .then(() => {
          this.isTagDeleting = true;
          ApiService.tags.delete(this.container, this.tag.id)
            .then(() => {
              this.inDialog = false;
              this.isTagDeleting = false;

              var foundIndex = this.list.findIndex(t => t.id == this.tag.id);
              this.$delete(this.list, foundIndex);
            })
            .catch(error => {
              this.isTagDeleting = false;
              this.showError(error.response.data.error);
            });
        });
    }
  },

  filters: {
    storages: Filters.storages
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomSnackbar: Snackbar,
    CustomDialog: Dialog,
    SearchPanel
  }
};
</script>
