export default class AnonymousAccessObject {

  pinCode = null;
  allowDownload = false;
  startsOn = new Date();
  expireOn = new Date();

  constructor(access) {
    if (access) {
      this.pinCode = access.pinCode;
      this.allowDownload = access.allowDownload;
      this.startsOn = new Date(access.startsOn);
      this.expireOn = new Date(access.expireOn);
    }
  }

  toRequest() {
    return {
      PinCode: this.pinCode,
      AllowDownload: this.allowDownload,
      StartsOn: this.startsOn,
      ExpireOn: this.expireOn
    }
  }

  toDummyResponse() {
    return {
      pinCode: this.pinCode,
      allowDownload: this.allowDownload,
      startsOn: this.startsOn,
      expireOn: this.expireOn
    }
  }
}