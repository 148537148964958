import VersionObject from "./VersionObject";
import TagAccessObject from "./TagAccessObject";
import FileAccessObject from "./FileAccessObject";
import AnonymousAccessObject from "./AnonymousAccessObject";

export default class FileObject {

  id = null;
  systemName = null;
  mainTag = null;
  createdAt = null;
  version = 0;
  tags = [];
  versions = [];
  accesses = [];

  tagAccesses = [];
  fileAccesses = [];
  anonymousAccesses = [];

  constructor(file) {
    if (file) {
      this.id = file.id;
      this.systemName = file.systemName;
      this.mainTag = file.mainTag;
      this.version = file.version;
      this.createdAt = file.createdAt;
      this.tags = file.tags.map(t => t);
      this.versions = file.versions.map(v => new VersionObject(v));

      let accesses = [];
      file.tagAccesses.forEach((item) => accesses.push(new TagAccessObject(item)));
      file.fileAccesses.forEach((item) => accesses.push(new FileAccessObject(item)));
      file.anonymousAccesses.forEach((item) => accesses.push(new AnonymousAccessObject(item)));
      this.accesses = accesses;
    }
  }

  toRequest() {
    return {
      Id: this.id,
      SystemName: this.systemName,
      MainTag: this.mainTag,
      Tags: this.tags,
      FileAccesses: this.accesses.filter(a => a instanceof FileAccessObject).map(a => a.toRequest()),
      AnonymousAccesses: this.accesses.filter(a => a instanceof AnonymousAccessObject).map(a => a.toRequest())
    }
  }

  toDummyResponse() {
    return {
      id: this.id,
      systemName: this.systemName,
      mainTag: this.mainTag,
      version: this.version,
      createdAt: this.createdAt,
      tags: this.tags.length,
      versions: this.versions.map(v => v.toDummyResponse()),
      tagAccesses: this.accesses.filter(a => a instanceof TagAccessObject).map(a => a.toDummyResponse()),
      fileAccesses: this.accesses.filter(a => a instanceof FileAccessObject).map(a => a.toDummyResponse()),
      anonymousAccesses: this.accesses.filter(a => a instanceof AnonymousAccessObject).map(a => a.toDummyResponse())
    }
  }
}